import general from './general';

const index = {
    init: function () {
        document.addEventListener('DOMContentLoaded', () => {
            this.bestOffersToggle();
            this.officeSlider();
            this.catalogSlider();
            this.testimonials();
        })
    },
    officeSlider: function () {
        const office__slider = $(".office__slider");
        if (office__slider.length === 0) return;

        office__slider.flickity({
            cellAlign: "center",
            friction: 0.3,
            prevNextButtons: false,
            pageDots: false,
            on: {
                change: function() {
                    sliderChange();
                    $(".office__slider").find(".fb_loaded").removeClass("fb_loaded");
                    fast_background.update();
                },
                ready: function() {
                    if ($(window).width() > 767) {
                        setTimeout(function() {
                            $(".office__slider").flickity("select", 2);
                        });
                    }
                },
            },
        });

        function sliderChange() {
            const office__item_is_selected = $(".office__item.is-selected");
            var tag = office__item_is_selected.data("office");
            $(".office__item").removeClass("siblings-next siblings-prev");
            office__item_is_selected.next().addClass("siblings-next");
            office__item_is_selected.prev().addClass("siblings-prev");
            $('.location-nav button.office-tag').removeClass("active");

            var activeTag = $(`.location-nav button[data-office="${tag}"]`);
            var desc = activeTag.data("desc");

            activeTag.addClass('active');
            $('.office__subtitle').html(desc);
        }
        sliderChange();

        $(".office__button").on("click", function(event) {
            var index = $(".office__item.is-selected").index();
            if ($(this).hasClass("office__button_next")) {
                $(".office__slider").flickity("select", index + 1);
            } else if ($(this).hasClass("office__button_prev")) {
                $(".office__slider").flickity("select", index - 1);
            }
        });

        $('button.office-tag').on("click", function(event) {
            if ($(this).data("office") !== $('button.office-tag.active').data('office')) {
                $(".office__slider").flickity(
                    "select",
                    $(`.office__item[data-office="${$(this).data("office")}"]`).first().index()
                );
            }
        });
    },

    catalogSlider: function() {
        const catalog_carousel__slider = $(".catalog-carousel__slider");
        if (catalog_carousel__slider.length === 0) return;

        catalog_carousel__slider.flickity({
            cellAlign: "center",
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            freeScroll: true,
        });

        var sliderData = catalog_carousel__slider.data("flickity");
        sliderData.x = 0;

        function autoPlay() {
            sliderData.x = sliderData.x - 0.25;
            sliderData.settle(sliderData.x);
            let requestId = window.requestAnimationFrame(autoPlay);
        }

        autoPlay();
    },

    bestOffersToggle: function() {
        if ($('.offers-toggle').length === 0) return;

        $(document).on('click', '.offers-toggle__btn', function() {
            if ($(this).hasClass('active')) return;
            $('.offers-toggle__btn').removeClass('active');
            $(this).addClass('active');

            changeTypeRealty($(this).data('offers-type'), $(this).closest('.offers-toggle').data('url'));
        });

        function changeTypeRealty(type, url) {
            let wrapp = $('.best-offers');
            let container = $('#best-offers');

            $.ajax({
                url: url + '?type=' + type,
                beforeSend: () => {
                    wrapp.addClass('waiting');
                },
                success: (res) => {
                    wrapp.removeClass('waiting');

                    if (!res) {
                        container.hide();
                    } else {
                        container.css('display', 'block');
                    }

                    container.html(res);
                    fast_background.update();
                },
            });
        }
    },

    testimonials: function() {
        function switchTestimonials() {
            const data_testimonials = $("[data-testimonials]");
            data_testimonials.removeClass("show");
            $(`[data-testimonials=${$('[name="testimonials-tab"]:checked').val()}]`).addClass("show");
            data_testimonials.find(".scroll__container").scrollLeft(0);
            general.customScrollBar($("[data-testimonials].show"), $(window).width() < 768);
        }
        switchTestimonials();

        $('[name="testimonials-tab"]').on("change", function() {
            switchTestimonials();
        });
    },
};

general.init();
index.init();
